.staticContainer {

    cursor: pointer;
    display: flex;
    align-items: center;
}

.staticContainer:hover {
    text-decoration: underline;
}

.note {
    position: absolute;
    bottom: -20px;
    width: 150px;
    display: block;
    word-wrap: pre-wrap;
    font-size: 12px;
    color: #8c8c8c;
}

.actionButton {
    color: #8c8c8c;
}

.form {
    position: relative;
}
