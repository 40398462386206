.plantTypeList {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 8px 4px;
    border-radius: 12px;
}

.plantTypeList:hover {
    background-color: #f5f5f5;
}