.cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}


.popover {
    position: absolute;
    z-index: 2;
}

.button {
    display: flex;
    gap: 8px;
    cursor: pointer;
}

.button:hover{
    text-decoration: underline;
}


.dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #e0e0e0
}