.container {
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem;
    position: relative;
    background-color: #fff;
    background-position: 0;
    background-size: contain;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    gap: 12px;
    height: 100%;
}

.sensorIndicator {
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
}

.conditionsIndicator {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    font-weight: 500;
}