@import "./variables.css";

@tailwind base;
@tailwind components;
@tailwind utilities;


#__next, body {
  overflow-x: unset !important;
}
 
@layer base {
  :root {
    --background: #f5f5f5;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
 
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
 
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
 
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
 
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
 
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
 
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
 
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
 
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

#__next {
  overflow-x: unset !important;
}
a {
  color: inherit;
  text-decoration: none;
}
* {
  box-sizing: border-box;
}

body {
  background-color: var(--background) !important;
  color: var(--foreground) !important;
}

body::-webkit-scrollbar {
  display: none !important;
}
div[data-framer-component-type='Text'] span {
  font-size: var(--framer-font-size) !important;
  line-height: var(--framer-line-height) !important;
}
.stickyStyle {
  position: -webkit-sticky;
  position: sticky;
}
.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 20000;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gradientOverlay {
  background-color: red;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(245, 245, 245, 1) 90%);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family);
  font-weight: 500;
  margin: 0;
}
ul li:before {
  content: "" !important;
  list-style: none !important;
  padding: 0;
  margin: 0 !important;
}
.ais-Hits-list {
  margin: 0 !important;
}
.ais-SearchBox-input {
  border-radius: 12px;
  width: 100%;
  border: none;
  background-color: var(--color-primary-10);
  padding: 12px 20px;
}
.ais-SearchBox-submit,
.ais-SearchBox-reset {
  display: none;
}
@keyframes shakeAnimation {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
}