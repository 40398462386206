.qrCodeLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 0 0;
    padding: 0 12px;
    padding-right: 48px;
    flex-basis: 50%;
    border-right: 1px solid #e0e0e0;
}

.container {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 2px dashed black;
}