.container {
    padding: 18px 20px 18px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 18px;
}

.button {
    border-radius: 200px;
    color: white;
    font-weight: bold;
    font-size: 14px;
    padding: 4px 12px 5px 12px;
    cursor: pointer;
    white-space: nowrap;
}

.text {
    font-weight: 500;
}

.leftBlock {
    display: flex;
    gap: 8px;
}