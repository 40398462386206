.sidebar {
    background: white;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    align-content: stretch;
    top: 51px;
    padding: 20px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    left: 0;
    height: 100vh;
    justify-content: top;
    z-index: 20;
    padding-top: 40px;
}

.topBarItem:hover {
    cursor: pointer;
    color: var(--color-brand)
}

.expanded {
    width: 100%;
    display: block;

}

.actionSection {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    justify-content: center;
}

.mobile {
    display: none;
    width: 100%;
}

.topBar {
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(20px);
    top: 0;
    position: sticky;
    position: -webkit-sticky;
    height: 71px;
    z-index: 20;
}

.collapse {
    display: none;
}

@media (max-width: 940px) {
    .sidebar {
        padding: 10px;
        padding-top: 40px;
    }

    .expanded {
        display: none;

    }

    .collapse {

        display: block;
    }
}


@media (max-width: 480px) {

    .collapse {
        display: none;
    }

    .expanded {
        display: none;

    }

    .mobile {
        display: block;
    }

    .sidebar {
        background: white;
        display: flex;
        /* align-items: stretch; */
        flex-direction: column;
        align-content: center;
        align-items: center;
        justify-content: stretch;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        height: 100%;
        overflow: hidden;
        border-right: unset;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        justify-content: flex-start;
        z-index: 499;
        padding: 10px;
    }

    
}
