:root {

    --color-primary-100: #000;
    --color-primary-50: rgba(0, 0, 0, 0.5);
    --color-primary-10: rgba(0, 0, 0, 0.1);
    --color-primary-5: rgba(0, 0, 0, 0.05);
    --color-primary-4: rgba(0, 0, 0, 0.04);
    --color-primary-3: rgba(0, 0, 0, 0.03);
    --color-primary-2: rgba(0, 0, 0, 0.02);
    --color-primary-1: rgba(0, 0, 0, 0.01);

    --color-brand: rgb(142, 81, 200);
    --color-brand-50: rgba(142, 81, 200, 0.5);
    --color-brand-20: rgba(142, 81, 200, 0.2);
    --color-brand-10: rgba(142, 81, 200, 0.1);

    --color-success: rgb(0, 200, 0);
    --color-success-50: rgba(0, 200, 0, 0.5);

    --color-warning: rgb(255, 200, 0);
    --color-warning-50: rgba(255, 200, 0, 0.5);

    --color-error: rgb(255, 0, 0);
    --color-error-50: rgba(255, 0, 0, 0.5);

    --standard-border: 1px solid var(--color-primary-10);

    --icon-container-size: 50px;
    --icon-size: 40px;
    --icon-padding: 5px;

    --font-size-title: 17px;
    --font-weight-title: 600;
    --font-size-subtitle: 17px;
    --font-weight-subtitle: 400;

}