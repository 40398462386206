.listItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    padding: 12px 0px;
    border-bottom: var(--standard-border);
    cursor: pointer;
    width: 100%;
}

.listItemText {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.listItemIcon {
    width: 48px;
    flex: 0 0 auto;
    height: 48px;
    background-color: var(--color-primary-10);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

.listItemTitle {
    font-weight: 600;
    line-height: 1.5rem;
}

.listItemSubtitle {
    color: var(--color-primary-50)
}

.listItemChildren {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}