.bg {
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(10px);
}

.modalContainer {
    border-radius: 40px;
    padding: 20px;
    width: 400px;
    position: fixed;
    background-color: white;
    right: 40px;
    left: auto;
    z-index: 900;
}

/* Mobile Breakpoint */
@media screen and (max-width: 768px) {
    .modalContainer {
        right: 10px;
        left: 10px;
        width: auto;
        max-width: calc(100vw - 20px);
    }
}