.display:hover {
    background: #f5f5f5;
    border-radius: 6px;
}

.display {
    padding: 8px;
}

.displayIcon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    border-radius: 200px;
    color: rgb(156, 156, 156);
    border: 1px solid rgb(156, 156, 156);
}

.unset {
    border: 1px solid #ccc;
}

.image {
    border-radius: 200px;
}
