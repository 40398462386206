.bar {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0px;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background: rgba(255, 255, 255, 0.5);
    border-top: 1px solid rgba(0, 0, 0, 0.055);
    padding: 10px;
    display: flex;
    height: 80px;
    z-index: 500;
    align-items: stretch;
    justify-content: center;
}

.container {
    max-width: 80%;
    margin: auto;
}

.grid {
    display: flex;
    align-items: center;
}

.tabItem {
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: center;
    align-items: center;
}