.page {
    padding: 20px;
    /* padding-top: 71px; */
    /* overflow: scroll; */
    overscroll-behavior: 'auto' !important;
    padding-bottom: 80px;
    /* width: 100%; */
    margin: auto;
}

.main {
    top: 0;
    margin-left: 250px;
    bottom: 0;
    right: 0;
}

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    bottom: 0;
    width: 250px;
    padding-top: 72px;
}

.topBar {
    width: 100%;
    z-index: 700;
    position: sticky;
    top: 0;
    position: -webkit-sticky;
}

@media (max-width: 950px) {

    .main {
        margin-left: 80px;
    }

    .sidebar {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: 80px;
        /* width: 60px; */
        /* overflow: hidden; */
    }

    .topBar {
        width: 100%;
    }

}


@media (max-width: 480px) {

    .main {
        margin-left: 0px;
        bottom: 0;
    }

    .sidebar {
        position: fixed;
        right: 0;
        left: 0;
        top: unset;
        z-index: 400;
        bottom: 0;
        width: 100%;
        min-height: 80px;
        /* width: 60px; */
        /* overflow: hidden; */
    }
}


.reportPageHeader {
    padding: 8px 20px;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    background: rgba(255, 255, 255, 0.8);
    border-bottom: 1px solid #e0e0e0;
    backdrop-filter: blur(20px);
    z-index: 999999;
}

.reportPage {
    
    padding: 20px;
    max-width: 960px;
    margin: 0 auto;
}

.pdfPage {
    width: 21cm;
    min-height: 29.7cm;
    margin: 0 auto;
    padding: 40px;
    /* font-size: 1.5rem; */
    background-color: white;
}