.icon {
    background-color: var(--color-primary-10);
    border-radius: 8px;
    width: 50px;
    height: 50px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
}