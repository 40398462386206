.card {
    padding: 0;
}

.toolbar {
    border-bottom: 1px solid #e0e0e0;
    padding: 16px;
    display: flex;
    position: sticky;
    position: -webkit-sticky;
    top: 120px;
    z-index: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.row:hover {
    background-color: #fbfbfb;
    /* cursor: pointer; */
}

.table {
    display: table;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

.col {
    display: table-cell;
    padding: 0 10px;
}

.chip {
    box-sizing: border-box;
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px 12px 16px;
    background-color: rgba(142, 81, 200, 0.14);
    overflow: visible;
    align-content: center;
    flex-wrap: nowrap;
    gap: 4;
    border-radius: 200px;
    font-weight: 500;
    font-family: "system-ui", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif, "System Default", serif;
    color: var(--Purp, #8e51c8);
    font-size: 12px;
    letter-spacing: -0.3px;
    line-height: 16px;
    text-align: left;
    cursor: pointer;
}

.cell {
    padding: 8px 12px;
    text-align: left;
    border-bottom: 1px solid #f0f0f0;
}

.tableBody {
    overflow-x: scroll;
}

.filterOption {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding: 12px 16px 12px 16px; */
    /* overflow: visible; */
    align-content: center;
    flex-wrap: nowrap;
    gap: 4px;
    border-radius: 200px;
    font-weight: 500;
    font-family: "system-ui", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif, "System Default", serif;
    text-align: left;
    cursor: pointer;
}

.footer {
    padding: 20px 12px;
}