.container {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 9px 24px 9px 9px;
}

.title {
    font-weight: 600;
}

.left {
    display: flex;
    flex: 1;
    gap: 8px;
}

.strain {
    opacity: 0.5;
}

.batchIndicator {
    display: flex;
    align-items: center;
    gap: 4px;
    opacity: 0.5;
}