.wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 12px 0;
    font-size: 16px;
}

.label {
    margin-top: 3px;
    width: 100%;
}

.title {
    color: var(--color-primary-100);
    font-size: var(--font-size-title);
    font-weight: var(--font-weight-title);
}

.subtitle {
    color: var(--color-primary-50);
    font-size: var(--font-size-subtitle);
    font-weight: var(--font-weight-subtitle);
}