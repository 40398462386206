.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
    gap: 2rem;
}

.description {
    opacity: 0.6;
}

.leftBlock {
    flex: 1;
    display: flex;
    direction: row;
    gap: 2rem;
    align-items: center;
}

.image {
    position: relative;
    width: 50px;
    height: 50px;
}

.icon {
    position: absolute;
    bottom: 0;
    display: flex;
    right: 0;
    padding: 6px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 200px;
    backdrop-filter: blur(10px);
    color: white;
}